.bannerHome {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  /*min-height: 100vh;  Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-input-wrapper {
  margin-bottom: 33px;
}

.banner-input-content label::before,
.banner-input-content label::after {
  display: none !important;
}

.toggle-btn-container {
  display: flex;
  gap: 32px;
  justify-content: center;
}

.jobDurationList > span {
  color: black;
  font-size: 16px;
  padding-top: 0px;
}

@media screen and (max-width: 767px) {
  .banner-input-content:last-child label,
  .banner-input-content:last-child input {
    border-left: none;
    padding-left: 35px;
  }
  .toggle-btn-container {
    gap: 20px;
  }
  .banner-input-content:last-child {
    border-left: none;
  }
}
