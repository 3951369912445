/* Blog Wrapper */
.blog-view-wrapper {
  max-width: 1000px;
  margin: 0 auto;
  font-family: "Georgia", serif;
  padding: 20px;
  line-height: 1.8; /* Increase line height for better readability */
  word-break: break-word; /* Break long words to prevent overflow */
}

/* Header */
.blog-header {
  margin-bottom: 20px;
}

.blog-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.blog-author {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.8;
}

.highlight {
  color: rgb(22, 78, 212); /* Blue highlight for author and metadata */
}

/* Container to position the image and sidebar */
.image-sidebar-container {
  display: grid;
  grid-template-columns: 1fr 50px; /* Main content (image) and a narrow column for the sidebar */
  gap: 10px; /* Adjust gap between the image and sidebar */
  align-items: start;
}

.blog-image img {
  max-width: 100%; /* Make sure the image scales properly */
  height: auto; /* Keep the aspect ratio */
}

.image-credits {
  font-size: 0.8rem;
  color: #888;
  margin-top: 5px;
  margin-bottom: 20px;
  line-height: 1.8;
}

.blog-content {
  margin-top: 20px;
  font-size: 1.2rem;
  line-height: 1.8; /* Increase line height for improved readability */
}

/* Typography Refinements */

.blog-paragraph {
  margin-bottom: 20px;
  line-height: 1.8;
}

.blog-subheading {
  font-size: 1.8rem; /* Ensure headings are prominent */
  margin-top: 30px;
  margin-bottom: 15px;
}

.blog-list {
  margin: 20px 0;
  padding-left: 20px;
}

.blog-list-item {
  margin-bottom: 10px; /* Add space between list items */
}

/* Social sidebar styles */
.social-sidebar {
  position: relative; /* Relative to the container */
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Spacing between icons */
  z-index: 100; /* Ensure it stays above the image */
}

.social-icon svg {
  width: 40px; /* Increase icon size */
  height: 40px;
  background-color: rgb(22, 78, 212, 0.1); /* Light blue background */
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.social-icon svg:hover {
  background-color: rgb(22, 78, 212, 0.3); /* Darker background on hover */
  transform: scale(1.1); /* Slight zoom effect on hover */
}

/* Blog Content Styling */
.paragraph {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px; /* Increase bottom margin for more spacing between paragraphs */
}

/* Link styling inside the blog content */
.blog-content a {
  color: rgb(22, 78, 212); /* Your preferred blue color */
  text-decoration: none; /* Remove default underline */
  border-bottom: 1px solid rgb(22, 78, 212); /* Underline like in the example */
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.blog-content a:hover {
  color: rgb(22, 78, 212, 0.7); /* Slight color change on hover */
  border-bottom: 1px solid rgb(22, 78, 212, 0.7); /* Slight fade on hover */
}

/* ========================= */
/* Responsive Styles */
/* ========================= */

/* Tablet Screens (1024px and smaller) */
@media screen and (max-width: 1024px) {
  .blog-title {
    font-size: 2rem; /* Decrease font size for blog title */
  }

  .blog-content {
    font-size: 1.1rem; /* Slightly smaller font size for tablet screens */
  }

  .image-sidebar-container {
    grid-template-columns: 1fr 40px; /* Reduce sidebar width for tablets */
    gap: 15px; /* Adjust spacing between image and sidebar */
  }

  .social-sidebar {
    gap: 15px; /* Reduce gap between social icons */
  }

  .social-icon svg {
    width: 35px;
    height: 35px;
  }
}

/* Mobile Screens (768px and smaller) */
@media screen and (max-width: 768px) {
  .blog-view-wrapper {
    padding: 10px; /* Reduce padding */
  }

  .blog-title {
    font-size: 1.8rem; /* Reduce blog title font size for mobile */
  }

  .blog-author {
    font-size: 0.8rem; /* Adjust author text size for readability */
  }

  .blog-content {
    font-size: 1rem; /* Adjust font size for mobile readability */
    line-height: 1.5; /* Slightly reduced line-height */
  }

  .image-sidebar-container {
    grid-template-columns: 1fr; /* Stack the image and sidebar */
  }

  .social-sidebar {
    position: static; /* Remove fixed positioning on mobile */
    flex-direction: row; /* Display icons in a row */
    justify-content: center;
  }

  .social-icon svg {
    width: 30px;
    height: 30px; /* Smaller icons on mobile */
    margin-right: 10px; /* Add space between icons */
  }
}

/* Small Mobile Screens (480px and smaller) */
@media screen and (max-width: 480px) {
  .blog-title {
    font-size: 1.5rem; /* Further reduce title font size */
  }

  .blog-author {
    font-size: 0.7rem; /* Further reduce author font size */
  }

  .blog-content {
    font-size: 0.9rem; /* Smaller font size for small screens */
  }

  .social-icon svg {
    width: 25px; /* Smaller icons for very small screens */
    height: 25px;
    padding: 8px;
  }
}
