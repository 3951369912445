.jobDetailsOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 999;
}

.jobDetailsCard {
  position: fixed;
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  background-color: white;
  border-radius: 8px 0 0 8px;
  box-shadow: -2px 0 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
}

.jobDetailsCardHeader {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  background-color: transparent;
}

.jobDetailsCardBody {
  padding: 16px;
  background-color: transparent;
}

.jobDetail-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.jobDetail-heading {
  font-size: 1rem;
  font-weight: 500;
}

.jobCardButtons {
  background-color: #4caf50;
  color: white;
}

.applyButton {
  background-color: #2196f3;
}
@media (max-width: 768px) {
  .jobDetailsCard {
    width: 100%;
    border-radius: 0;
  }
}

/* Style all strong, bold, and heading tags as subheadings */
.job-description-style strong,
.job-description-style b,
.job-description-style h1,
.job-description-style h2,
.job-description-style h3,
.job-description-style h4,
.job-description-style h5,
.job-description-style h6 {
  display: block; /* Makes them behave like block-level headings */
  font-size: 1.25em; /* Increases font size for emphasis */
  font-weight: bold; /* Ensures bold styling */
  margin-top: 1.5em; /* Adds space above */
  margin-bottom: 0.5em; /* Adds space below */
  color: #333; /* Optional: Darker color for subheadings */
}

/* Improve paragraph and list readability */
.job-description-style p {
  line-height: 1.8;
  margin-bottom: 1em;
}

.job-description-style ul,
.job-description-style ol {
  padding-left: 1.5em;
  margin-bottom: 1em;
}

.job-description-style li {
  margin-bottom: 0.5em;
}
