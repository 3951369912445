/* ContactForm.css */
/* ContactForm.css */

.bg-164ED4 {
  background-color: #164ed4 !important;
}

.hover\:bg-164ED4:hover {
  background-color: #164ed4 !important;
}

.focus\:ring-164ED4:focus {
  box-shadow: 0 0 0 4px rgba(22, 78, 212, 0.5) !important;
}

.focus\:border-164ED4:focus {
  border-color: #164ed4 !important;
}

.text-164ED4 {
  color: #164ed4 !important;
}
