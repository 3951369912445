.social-sidebar {
  position: fixed;
  top: 150px; /* Adjust as needed to align with the content */
  right: 20px; /* Fix the sidebar to the right */
  display: flex;
  flex-direction: column;
  gap: 10px; /* Decrease the gap between the icons */
  z-index: 100; /* Ensure it appears above the content */
}

.social-icon svg {
  width: 20px; /* Decrease icon size */
  height: 2px; /* Make icons smaller */
  background-color: rgba(22, 78, 212, 0.1); /* Light blue background */
  padding: 6px; /* Reduce padding for a more compact look */
  border-radius: 50%; /* Make them circular */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth hover effect */
}

.social-icon svg:hover {
  background-color: rgba(22, 78, 212, 0.3); /* Slightly darker on hover */
  transform: scale(1.1); /* Slight zoom on hover */
}
