.bannerHome {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}
.banner-input-wrapper {
  margin: 15px auto;
  margin-bottom: 33px;
}
@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.banner-input-content label::before,
.banner-input-content label::after {
  display: none !important;
}
.banner-input-content:last-child {
  border-left: 1px solid #b5c6f1; /* Change border-right instead of border-left */
}
/* .banner-input-content:last-child label,
.banner-input-content:last-child input {
  padding-left: 45px;
} */
.toggle-btn-container {
  display: flex;
  gap: 32px;
  justify-content: center;
}

.jobDurationList > span {
  color: black;
  font-size: 16px;
  padding-top: 0px;
}

@media screen and (max-width: 767px) {
  .banner-input-content:last-child label,
  .banner-input-content:last-child input {
    border-left: none;
    padding-left: 35px;
  }
  .toggle-btn-container {
    gap: 20px;
  }
  .banner-input-content:last-child {
    border-left: none;
  }
}
