.card-title {
  color: var(--Gray-Black-500, #1d1f2c);
  font-weight: 700;
  line-height: 120%;
  white-space: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
}

.workType-Container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.workType {
  color: var(--Gray-Black-500, #1d1f2c);
  font-size: 18px;
  line-height: 120%; /* 21.6px */
  padding: 0px 16px 0px 16px;
}

.typeWithBorder {
  border-right: 1px solid #b5c6f1;
}

.description {
  color: var(--Gray-Black-400, #4a4c56);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.card-tag {
  color: var(--Main-Colors-Primary, #4864e1);
  font-size: 14px;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
  margin-bottom: 8px;
}

.jobSalary {
  color: var(--Gray-Black-500, #1d1f2c);
  font-size: 18px;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
}

.salaryDuration {
  color: var(--Gray-Black-500, #1d1f2c);
  font-size: 14px;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}

.jobValid {
  color: var(--Gray-Black-500, #1d1f2c);
  text-align: right;
  font-size: 14px;
  line-height: 22px;
}

.jobCardButtons {
  display: flex;
  width: 177px;
  height: 46px;
  padding: 17px var(--12px, 12px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--12px, 12px);
}

.detailsButton {
  display: flex;
  width: 177px;
  height: 46px;
  padding: 17px var(--12px, 12px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--12px, 12px);
  background: var(--background-normal-25, #f6f8fa);
  color: var(--Gray-Black-300, #777980);
  font-size: 14px;
}

.applyCardButton {
  background-color: #f6f8fa;
  color: #164ed4;
  font-size: 14px;
  &:hover {
    background: var(--Blue-blue-500, #164ed4);
    color: var(--W, #fff);
  }
}
@media screen and (max-width: 767px) {
  .jobCardButtons,
  .detailsButton {
    width: 50% !important;
  }
}
