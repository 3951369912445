.profile-title {
  color: var(--Gray-Black-500, #1d1f2c);
  font-feature-settings: clig off, liga off;
  font-size: 18px;
  font-weight: 600;
}

.profile-name {
  color: var(--Gray-Black-500, #1d1f2c);
  font-feature-settings: clig off, liga off;
  font-size: 32px;
  font-weight: 400;
}

.profile-email {
  color: var(--Gray-Black-500, #1d1f2c);
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.professional-skills {
  color: var(--Gray-Black-400, #4a4c56);
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.08px;
}

.software-skills {
  color: var(--Gray-Black-400, #4a4c56);
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.08px;
}

.recentApplied {
  color: var(--Gray-Black-500, #1d1f2c);
  font-size: 20px;
  font-weight: 500;
  line-height: 120%; /* 24px */
}

@media screen and (max-width: 767px) {
  .profile-title {
    font-size: 14px;
  }
  .profile-name {
    font-size: 24px;
  }
}
