.progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.step {
  text-align: center;
  flex: 1;
  position: relative;
}

.step-number {
  width: 30px;
  height: 30px;
  margin: 0 auto 10px;
  border-radius: 50%;
  background-color: #e0e0e0;
  line-height: 30px;
  color: #fff;
}

.step.active .step-number {
  background-color: #4caf50; /* Active step color */
}

.step.completed .step-number {
  background-color: #4caf50; /* Completed step color */
}

.step.inactive .step-number {
  background-color: #bdbdbd; /* Inactive step color */
}

.step-label {
  color: #757575;
}

.step.active .step-label,
.step.completed .step-label {
  color: #000;
}

.step.inactive .step-label {
  color: #bdbdbd; /* Inactive step label color */
}

.step:before,
.step:after {
  content: "";
  position: absolute;
  top: 15px;
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;
}

.step:after {
  right: 50%;
  width: 50%;
}

.step:before {
  left: 50%;
  width: 50%;
}

.step:first-child:before {
  display: none;
}

.step:last-child:after {
  display: none;
}

.step.active:before,
.step.active:after,
.step.completed:before,
.step.completed:after {
  background-color: #4caf50; /* Active and completed step color */
}
